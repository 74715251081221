import React from 'react';
import { Router } from '@reach/router';

import { Login, MerchantLogin } from '../components/routes';

const MerchantLoginPage = () => {
  return (
    <Router basepath="/login">
      <MerchantLogin path="/:id" />
      <Login default />
    </Router>
  );
};

export default MerchantLoginPage;
